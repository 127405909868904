import clsx from "clsx";
import React from "react";
import { useInView } from "react-intersection-observer";

import { CheckIcon } from "@heroicons/react/outline";

import styles from "./index.module.css";

export enum CheckVariants {
  PRIMARY = "primary",
  GREEN = "green",
  TRANSPARENT = "transparent",
}

type Props = {
  animate?: boolean;
  className: string;
  checkClassName?: string;
  delay?: number;
  iconClassName: string;
  loaded?: boolean;
  variant?: CheckVariants;
};

const AnimatedCheck = ({ className, delay, iconClassName, animate, variant = CheckVariants.GREEN, loaded }: Props) => {
  const { inView, ref } = useInView({ threshold: 0.6, triggerOnce: true });
  return (
    <div className={className} ref={ref}>
      <div
        className={clsx(
          styles.animatedCheck,
          delay && styles[`delay${delay}`],
          (animate === undefined ? inView && (loaded === undefined || loaded) : animate) && styles.animate,
          variant && styles[variant]
        )}
      >
        <CheckIcon className={iconClassName} />
      </div>
    </div>
  );
};

export default AnimatedCheck;
