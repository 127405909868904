import React, { useState } from "react";

import AnimatedCheck from "components/animated-check";
import Heading from "components/heading";
import Image from "next/image";
import FigureImageMobile from "public/images/enerflo-solar-project-management-mobile.png";
import FigureImage from "public/images/enerflo-solar-project-management.png";

import HeroSection from "../../hero-section";

const title = "Manage More Projects";

const imageAlt =
  "Enerflo Project Tracker, built exclusively for solar, automates solar project management, giving everyone involved the visibility they need to follow their installation progress, delays, commission data and more.";

const ProjectManagement = () => {
  const [figureLoaded, setFigureLoaded] = useState(false);
  return (
    <div className="bg-gradient-to-tr from-navy-dark to-primary">
      <HeroSection title={title} className="flex-col text-white md:space-x-0">
        <HeroSection.Content className="md:text-center w-full px-6 md:w-2/3 xl:w-3/5 md:p-8 xl:px-14">
          <HeroSection.Header>
            <Heading tag="h2">{title}</Heading>
          </HeroSection.Header>
          <HeroSection.Description className="text-gray-200 text-center">
            Enerflo Project Tracker (AKA WingMan) has your back, giving everyone involved the visibility they need to
            follow their installation progress, access data, build reports and more. Better yet, the data can be powered
            by your favorite CRM, or simply use our tools out of the box.
          </HeroSection.Description>

          <HeroSection.Action className="btn-white" href="/features/project-management">
            Manage Like a Boss
          </HeroSection.Action>
        </HeroSection.Content>

        <figure className="w-full relative">
          <div className="hidden md:block">
            <Image
              src={FigureImage}
              alt={imageAlt}
              quality={100}
              layout="responsive"
              placeholder="blur"
              className="crispImage"
              onLoadingComplete={() => setFigureLoaded(true)}
              lazyBoundary="400px"
            />
          </div>
          <div className="ml-4 sm:ml-14 md:hidden">
            <Image
              src={FigureImageMobile}
              alt={imageAlt}
              quality={100}
              layout="responsive"
              className="crispImage"
              lazyBoundary="400px"
            />
          </div>
          <AnimatedCheck
            className="absolute top-[5%] left-[15%] md:left-[28%]"
            iconClassName="h-10 w-10 sm:h-14 sm:w-14"
            loaded={figureLoaded}
          />
          <AnimatedCheck
            className="absolute top-[15%] left-[25%] md:left-[38%] hidden sm:block"
            delay={1}
            iconClassName="h-8 w-8"
            loaded={figureLoaded}
          />
          <AnimatedCheck
            className="absolute top-[12%] right-[10%]"
            delay={2}
            iconClassName="h-4 w-4 sm:h-5 sm:w-5"
            loaded={figureLoaded}
          />
          <AnimatedCheck
            className="absolute bottom-[10%] lg:bottom-[6%] right-[40%] md:right-[30%]"
            delay={3}
            iconClassName="h-6 w-6 sm:h-8 sm:w-8"
            loaded={figureLoaded}
          />
          <AnimatedCheck
            className="absolute bottom-0 right-[25%]"
            delay={4}
            iconClassName="h-3 w-3 sm:h-4 sm:w-4"
            loaded={figureLoaded}
          />
        </figure>
      </HeroSection>
    </div>
  );
};

export default ProjectManagement;
